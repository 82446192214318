import React from 'react'
import moment from 'moment-timezone'
import classnames from 'classnames'
import css from './styles.module.css'

function getCavityStyle (state) {
  switch (state) {
    case 'early':
    case 'late':
    case 'wrong day':
    case 'no timestamp': return 'wrong'
    case 'ok': return 'ok'
    case 'missed': return 'missed'
    case 'await': return 'await'
    default: return 'disabled'
  }
}

function TimeText ({ state, dateOpened, timezone }) {
  let text
  switch (state) {
    case 'missed':
    case 'await':
    case 'no timestamp': text = '- - : - -'
      break
    case 'disabled': text = ''
      break
    default: text = moment(dateOpened).tz(timezone).format('hh:mm A')
  }
  return <div className={css.timeText}>{text}</div>
}

function DateText ({ state, dateOpened, timezone }) {
  let text
  switch (state) {
    case 'missed': text = 'NOT OPENED'
      break
    case 'no timestamp': text = 'OPENED'
      break
    case 'await': text = 'NOT OPENED'
      break
    case 'disabled': text = ''
      break
    default: text = moment(dateOpened).tz(timezone).format('MMM DD, ddd')
  }
  return <div className={css.dateText}>{text}</div>
}

function StateLabel ({ state }) {
  return <div className={css.stateLabel}>{state}</div>
}

function Cavity ({ cavity, timezone }) {
  const state = cavity.status
  const style = getCavityStyle(state)

  return (
    <div className={classnames(css.cavity, css[style])}>
      <TimeText state={state} dateOpened={cavity.dateOpened} timezone={timezone} />
      <DateText state={state} dateOpened={cavity.dateOpened} timezone={timezone} />
      <StateLabel state={state} />
    </div>
  )
}

export default Cavity
