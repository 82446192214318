import React, { useState } from 'react'
import { Button, Intent, Slider, Tag, Classes, H6 } from '@blueprintjs/core'
import { devicesApi } from '../../../api'
import { serverErrorToast, successToast } from '../../layout/toaster/toaster'
import css from '../patient.module.css'
import moment from 'moment'
import { Col, Row } from 'react-grid-system'

const getStatusIntent = isConnected => isConnected ? Intent.SUCCESS : Intent.DANGER

const getStatus = isConnected => isConnected ? 'CONNECTED' : 'DISCONNECTED'

const getLastTransmissionTime = lastTransmission => {
  return moment.duration(moment().diff(moment(lastTransmission))).humanize()
}

const InfoRow = ({ label, value }) => {
  return (
    <Row style={{ marginBottom: 5 }}>
      <Col xs={4} md={3}><H6 className={Classes.TEXT_MUTED}>{label}</H6></Col>
      <Col>{value}</Col>
    </Row>
  )
}

const Gateway = ({ patient }) => {
  if (!patient.gateway) return 'No gateway associated'

  const { gatewayId, status, lastMessageTime, isConnected } = patient.gateway

  const [currentVolumeLevel, setCurrentVolumeLevel] = useState(status.currentVolumeLevel || 0)
  const [currentScreenBrightness, setCurrentScreenBrightness] = useState(status.currentScreenBrightness || 0)

  const submitCommand = async (commands) => {
    try {
      await devicesApi.patch(`gateways/${patient.gatewayId}`, { commands })
      successToast(`Command was stored on server`)
    } catch (err) {
      serverErrorToast(err)
    }
  }

  return (
    <div className={css.overviewContainer}>
      <InfoRow label='Gateway ID' value={gatewayId} />
      <InfoRow label='Status' value={<Tag intent={getStatusIntent(isConnected)} round style={{ borderCollapse: 'initial' }}>{getStatus(isConnected)}</Tag>} />
      <InfoRow label='Last Message Time' value={getLastTransmissionTime(lastMessageTime)} />
      <InfoRow label='Battery Level' value={status.gatewayBatteryLevel} />
      <InfoRow label='Sim ID' value={status.simId} />
      <InfoRow label='Device Timestamp' value={status.currentTime} />
      <InfoRow label='Plugged In' value={<Tag intent={status.pluggedIn ? Intent.SUCCESS : Intent.DANGER} round style={{ borderCollapse: 'initial' }}>{status.pluggedIn ? 'PLUGGED IN' : 'UNPLUGGED'}</Tag>} />
      <InfoRow label='Volume Level' value={<>
        <div style={{ display: 'inline-block', width: 300, marginRight: 50 }} >
          <Slider min={0} max={100} value={currentVolumeLevel} onChange={value => setCurrentVolumeLevel(value)} labelStepSize={100} />
        </div>
        <Button intent={Intent.PRIMARY} text='Change Level' onClick={() => submitCommand({ currentVolumeLevel })} />
        </>} />
      <InfoRow label='Brightness Level' value={<>
        <div style={{ display: 'inline-block', width: 300, marginRight: 50 }} >
          <Slider min={0} max={100} value={currentScreenBrightness} onChange={value => setCurrentScreenBrightness(value)} labelStepSize={100} />
        </div>
        <Button intent={Intent.PRIMARY} text='Change Level' onClick={() => submitCommand({ currentScreenBrightness })} />
        </>} />
      <InfoRow
        label='Cancel Notification'
        value={<Button intent={Intent.PRIMARY} text='Cancel Notification' onClick={() => submitCommand({ cancelNotification: true })} />}
      />
      <InfoRow label='Reset' value={<Button
        intent={Intent.DANGER}
        text='Reset Gateway'
        onClick={() => submitCommand({ resetGateway: {
          resetRequested: true,
          resetRequestedAt: (+new Date())
        } })} />} />
    </div>
  )
}

export default Gateway
