import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Container } from 'react-grid-system'
import { Intent } from '@blueprintjs/core'
import TopBar from './layout/top-bar/top-bar'
import Routes from './routes'
import authentication from '../authentication'
import AppContext from './appContext'
import toaster from './layout/toaster/toaster'

function App () {
  const [authenticationData, setAuthenticationData] = useState(authentication.authenticationData)
  useEffect(() => {
    authentication.handleAuthentication(window.location.hash)
    const subscriptionId = authentication.subscribe(setAuthenticationData)
    return () => authentication.unsubscribe(subscriptionId)
  }, [])

  useEffect(() => {
    if (authenticationData.isAuthenticated && !authenticationData.hasPermissions) {
      setTimeout(() => {
        toaster.show({
          action: { onClick: authentication.logout, text: 'LOGOUT' },
          intent: Intent.PRIMARY,
          icon: 'error',
          message: 'Your user is not authorized to use this application. Please write to support@cuepath.io for further assistance.',
          timeout: 0
        })
      }, 500)
    }
  }, [authenticationData.isAuthenticated])

  if (!authenticationData.isAuthenticated || !authenticationData.hasPermissions) return null

  const user = authenticationData.user || {}

  return (
    <AppContext.Provider>
      <Router>
        <Container fluid style={{ maxWidth: '1400px' }}>
          <TopBar userName={user.name} userAvatar={user.picture} logoutHandler={authentication.logout} />
          { authenticationData.role === 'admin' && !authenticationData.organizationId ? 'Please choose an account' : <div style={{ marginTop: '64px' }}><Routes /></div> }
        </Container>
      </Router>
    </AppContext.Provider>
  )
}

export default App
