import React from 'react'
import moment from 'moment-timezone'
import { Text } from '@blueprintjs/core'
import { getAlertMessages } from '../../../../utils'
import css from './charts.module.css'

function AdherenceTrendAnalysis ({ packs, period, timezone }) {
  const startDateInTimezone = moment.tz([period[0].year(), period[0].month()], timezone).startOf('month')
  const endDateInTimezone = moment.tz([period[1].year(), period[1].month()], timezone).endOf('month')
  const alertMessages = getAlertMessages(packs, startDateInTimezone, endDateInTimezone, timezone).map((message, i) => <Text key={i}>{message}</Text>)
  return (
    <fieldset className={css.trendAnalysis}>
      <legend className={css.trendAnalysisLegend}>Adherence Trend Analysis</legend>
      {alertMessages}
    </fieldset>
  )
}

export default AdherenceTrendAnalysis
