import React from 'react'
import Cavity from './cavity'
import ColumnHeader from './column-header'
import css from './styles.module.css'

function Column ({ column, columnKey, timezone }) {
  const cavities = Object.keys(column)
    .filter(key => key.indexOf('row') !== -1)
    .sort()
    .map(key => <Cavity cavity={column[key]} key={key} timezone={timezone} lastTransmission={column.confirmationTimestamp} />)

  return (
    <div className={css.column}>
      <ColumnHeader column={column} columnKey={columnKey} timezone={timezone} />
      {cavities}
    </div>
  )
}

export default Column
