import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Button, Intent, HTMLSelect } from '@blueprintjs/core'
import css from '../../patient.module.css'

function Timings ({ patient, onSave, useCustomTimings }) {
  const clearedInput = {
    breakfastStart: 7,
    breakfastFinish: 9,
    lunchStart: 11,
    lunchFinish: 13,
    supperStart: 16,
    supperFinish: 18,
    bedtimeStart: 20,
    bedtimeFinish: 22
  }
  const [input, setInput] = useState(clearedInput)

  useEffect(() => {
    if (!patient || !patient.packSchedule) return

    setInput({
      breakfastStart: patient.packSchedule.column0.start,
      breakfastFinish: patient.packSchedule.column0.finish,
      lunchStart: patient.packSchedule.column1.start,
      lunchFinish: patient.packSchedule.column1.finish,
      supperStart: patient.packSchedule.column2.start,
      supperFinish: patient.packSchedule.column2.finish,
      bedtimeStart: patient.packSchedule.column3.start,
      bedtimeFinish: patient.packSchedule.column3.finish
    })
  }, [patient])

  useEffect(() => {
    if (!useCustomTimings) setInput({ ...clearedInput })
  }, [useCustomTimings])

  async function handleSubmit () {
    const packSchedule = {
      column0: {
        start: input.breakfastStart,
        finish: input.breakfastFinish
      },
      column1: {
        start: input.lunchStart,
        finish: input.lunchFinish
      },
      column2: {
        start: input.supperStart,
        finish: input.supperFinish
      },
      column3: {
        start: input.bedtimeStart,
        finish: input.bedtimeFinish
      }
    }

    onSave({ packSchedule, useCustomTimings })
  }

  function handleInputChange (event) {
    const { id, value } = event.target
    setInput({ ...input, [id]: parseInt(value) })
  }

  const inputIsValid = (input) => {
    return input.breakfastStart < input.breakfastFinish &&
      input.lunchStart < input.lunchFinish &&
      input.supperStart < input.supperFinish &&
      input.bedtimeStart < input.bedtimeFinish
  }

  const hourOptions = []
  for (let i = 0; i < 24; i++) {
    let text
    if (i === 0) text = '12AM'
    else if (i === 12) text = '12PM'
    else if (i < 12) text = `${i}AM`
    else text = `${i - 12}PM`
    hourOptions.push(<option value={i} key={i}>{text}</option>)
  }

  const TimingInput = ({ id }) => (
    <HTMLSelect disabled={!useCustomTimings} id={id} className={css.timingsInput} onChange={handleInputChange} value={input[id]}>
      {hourOptions}
    </HTMLSelect>
  )

  return (
    <FormGroup>
      <Label>
          Breakfast
      </Label>
      <div className={css.timingsRange}>
        <TimingInput id='breakfastStart' />
        <Label className='bp3-inline'>-</Label>
        <TimingInput id='breakfastFinish' />
      </div>

      <Label>
          Lunch
      </Label>
      <div className={css.timingsRange}>
        <TimingInput id='lunchStart' />
        <Label className='bp3-inline'>-</Label>
        <TimingInput id='lunchFinish' />
      </div>

      <Label>
          Supper
      </Label>
      <div className={css.timingsRange}>
        <TimingInput id='supperStart' />
        <Label className='bp3-inline'>-</Label>
        <TimingInput id='supperFinish' />
      </div>

      <Label>
          Bedtime
      </Label>
      <div className={css.timingsRange}>
        <TimingInput id='bedtimeStart' />
        <Label className='bp3-inline'>-</Label>
        <TimingInput id='bedtimeFinish' />
      </div>

      <Button disabled={!inputIsValid(input)} intent={Intent.SUCCESS} className={css.timingsButton} onClick={handleSubmit}>Save</Button>
    </FormGroup>
  )
}

export default Timings
