import React, { useState } from 'react'
import PackSettings from './pack-settings'
import { Row, Col } from 'react-grid-system'
import Timings from './timings'
import { Switch, Divider } from '@blueprintjs/core'
import { patientsApi } from '../../../../api'
import { serverErrorToast, successToast } from '../../../layout/toaster/toaster'

function Schedule ({ patient, onSave }) {
  const [useCustomTimings, setUseCustomTimings] = useState(patient.useCustomTimings)

  function handleInputChange (event) {
    const { checked } = event.target
    setUseCustomTimings(!checked)
  }

  async function savePatient (data) {
    try {
      await patientsApi.patch(patient._id, data)
      onSave()
      successToast('Profile successfully updated.')
    } catch (err) {
      serverErrorToast(err)
    }
  }

  return (
    <>
      <Switch id='useDefault' checked={!useCustomTimings} onChange={handleInputChange} label='Use default medication schedule' />
      <Divider />
      <Row >
        <Col md={3} style={{ paddingTop: 15 }} >
          <Timings patient={patient} onSave={savePatient} useCustomTimings={useCustomTimings} />
        </Col>
        <Col md={6} >
          <PackSettings patient={patient} onSave={savePatient} />
        </Col>
      </Row>
    </>
  )
}

export default Schedule
