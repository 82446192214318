import React from 'react'
import { withRouter } from 'react-router'
import { Text, H5, Divider, Colors, H4 } from '@blueprintjs/core'
import Skeleton from 'react-loading-skeleton'
import { Row, Col } from 'react-grid-system'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'

import Pack from '../../common/pack'
import { PatientInfo } from '../../common/patientInfo'
import { findCurrentPack, findNextPreparedPack, findLastPack, overallStatistics } from '../../../utils'

import css from '../patient.module.css'

function PatientPack ({ patient, packs, onDelete }) {
  if (!packs) {
    return (
      <div>
        <Skeleton height={200} />
        <Skeleton height={100} count={7} />
      </div>
    )
  }
  let renderPack = findCurrentPack(packs)

  if (!renderPack) renderPack = findNextPreparedPack(packs)
  if (!renderPack) renderPack = findLastPack(packs)

  if (!renderPack) {
    return (
      <div>
        <Text>No active packs</Text>
      </div>
    )
  }

  return (
    <Pack pack={renderPack} options={{ timezone: patient.timezone }} onDelete={onDelete} />
  )
}

function PatientAdherenceScore ({ patient, packs }) {
  if (!packs) {
    return <Skeleton height={300} />
  }
  if (!packs.length) {
    return 'No activity'
  }

  const stats = overallStatistics(packs, patient.timezone)

  if (!stats.total) {
    return 'No activity'
  }

  const data = [
    { name: 'Doses taken on-time', value: stats.ok || 0, color: Colors.GREEN3 },
    { name: 'Doses taken at wrong time', value: (stats['late'] || 0) + (stats['early'] || 0) + (stats['wrong day'] || 0) + (stats['no timestamp'] || 0), color: '#fbc73d' },
    { name: 'Missed doses', value: stats['missed'] || 0, color: Colors.RED3 }
  ]

  return (
    <>
      {data.map(({ name, value, color }) => {
        return <H4 key={name} style={{ color }}>
          {name}: <span style={{ fontSize: '0.7em', color: 'black' }}>{(value / stats.total * 100).toFixed(0)}%</span>
        </H4>
      })}
      <div className={css.adherenceScore}>
        <ResponsiveContainer>
          <PieChart>
            <Pie isAnimationActive={false} dataKey='value' data={data} innerRadius={40} label={({ name, percent }) => {
              return `${(percent * 100).toFixed(0)}%`
            }}>
              {
                data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

function renderPatientOverview ({ patient, packs, onChange }) {
  return (
    <div className={css.overviewContainer}>
      <Row >
        <Col md={4}>
          <Row >
            <H5>Personal Information</H5>
          </Row>
          <Divider />
          <PatientInfo patient={patient} className={css.patientInfoContainer} />
          <Row className={css.adherenceScoreContainer}>
            <H5>Lifetime Adherence Score</H5>
          </Row>
          <Divider />
          <Row className={css.adherenceMessageContainer}>
            <PatientAdherenceScore patient={patient} packs={packs} />
          </Row>
        </Col>
        <Col >
          <PatientPack packs={packs} patient={patient} onDelete={onChange} />
        </Col>
      </Row>
    </div>
  )
}

export default withRouter(renderPatientOverview)
