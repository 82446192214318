import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Patients from './patients/patients'
import Patient from './patient/patient'
import Profile from './profile/profile'
import Help from './help/help'
import NotFound from './layout/not-found/not-found'
import authentication from '../authentication'

function Routes () {
  return (
    <>
      <Switch>
        <Route exact path='/' render={() => <Redirect to='/patients' />} />
        <Route path='/auth0_callback' render={() => <Redirect to={authentication.getPreviousRoute()} />} />
        <Route path='/profile' component={Profile} />
        <Route path='/help' component={Help} />
        <Route path='/patients/:medId' component={Patient} />
        <Route path='/patients' component={Patients} />
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

export default Routes
