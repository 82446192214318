import React, { useState, useEffect } from 'react'
import { HTMLTable, Icon, InputGroup, H1, H2, H5, Intent, ButtonGroup, Button, Dialog } from '@blueprintjs/core'
import { Container, Row, Col } from 'react-grid-system'
import Skeleton from 'react-loading-skeleton'
import PatientForm from '../patient/patient-form'
import { packsApi, patientsApi } from '../../api'
import { getTodayStatus } from '../../utils/packs'
import authentication from '../../authentication'
import { serverErrorToast } from '../layout/toaster/toaster'

async function fetchPatients () {
  let response
  try {
    response = await patientsApi.get('')
  } catch (err) {
    serverErrorToast(err)
    return []
  }
  const patients = response.data
  return patients || []
}

async function fetchActivePacks (patientsIds) {
  let response
  try {
    const params = {
      patientId: patientsIds,
      status: 'active'
    }
    response = await packsApi.get('', { params })
  } catch (err) {
    serverErrorToast(err)
    return []
  }
  const packs = response.data
  return packs
}

async function populateActivePacks (patients) {
  const idToPatients = {}
  const patientsIds = patients.map((patient) => {
    patient.activePacks = []
    idToPatients[patient._id] = patient
    return patient._id
  }).filter(id => id && id.length > 0)

  const allActivePacks = await fetchActivePacks(patientsIds)
  for (let i = 0; i < allActivePacks.length; i++) {
    const pack = allActivePacks[i]
    const patient = idToPatients[pack.patientId]
    if (!patient) {
      console.log(`Error: fetched a pack with patientId: ${pack.patientId} that doesn't exist in the patient list`)
      continue
    }

    if (!patient.todayCavityStatus) patient.todayCavityStatus = getTodayStatus(pack, patient.timezone)

    if (!patient.activePacks) patient.activePacks = []
    patient.activePacks.push(pack)
  }
}

function getCavityIcon (state) {
  switch (state) {
    case 'early':
    case 'late':
    case 'wrong day':
    case 'no timestamp':
    case 'ok': return 'tick'
    case 'missed': return 'cross'
    default: return 'small-minus'
  }
}

function getCavityIntent (state) {
  switch (state) {
    case 'early':
    case 'late':
    case 'no timestamp':
    case 'wrong day': return Intent.WARNING
    case 'missed': return Intent.DANGER
    case 'ok': return Intent.SUCCESS
    default: return Intent.NONE
  }
}

const CavityStatus = ({ data }) => {
  if (!data) return 'no packs'

  const icons = data.map((status, key) => <Button key={key} icon={getCavityIcon(status)} intent={getCavityIntent(status)} />)
  return <ButtonGroup minimal>{icons}</ButtonGroup>
}

const PatientsTable = ({ patients, history, filterText }) => {
  if (!patients) {
    return (
      <div style={{ fontSize: 20, lineHeight: 2 }}>
        <Skeleton height={200} />
        <Skeleton height={50} count={5} />
      </div>
    )
  }
  if (patients.length === 0) {
    return (
      <div>
        <H1>No Patients found</H1>
      </div>
    )
  }

  if (filterText) {
    const re = new RegExp(filterText, 'i')
    patients = patients.filter(patient => (patient.firstName && patient.firstName.match(re)) || (patient.lastName && patient.lastName.match(re)) || (patient.medId && patient.medId.match(re)))
  }

  const tableRows = patients.map(patient => {
    return (
      <tr key={patient.medId} onClick={() => history.push(`/patients/${patient.medId}`)}>
        <td style={{ textAlign: 'center' }} >{patient.medId}</td>
        <td style={{ textAlign: 'center' }} ><H5>{`${patient.firstName} ${patient.lastName}`}</H5></td>
        <td style={{ textAlign: 'center' }} >
          <CavityStatus data={patient.todayCavityStatus} />
        </td>
        <td><Icon icon='chevron-right' /></td>
      </tr>
    )
  })

  return (
    <HTMLTable interactive style={{ width: '100%' }} >
      <thead>
        <tr style={{ fontSize: '20px' }} >
          <th style={{ textAlign: 'center' }} >Client ID</th>
          <th style={{ textAlign: 'center' }} >Name</th>
          <th style={{ textAlign: 'center' }} >Today's Adherence</th>
        </tr>
      </thead>
      <tbody style={{ textAlign: 'center' }}>
        {tableRows}
      </tbody>
    </HTMLTable>
  )
}

function PatientsList ({ history }) {
  const [patients, setPatients] = useState(null)
  const [filterText, setFilterText] = useState(null)
  const [isDialogOpen, setDialogOpen] = useState(false)

  const { role } = authentication.authenticationData

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData () {
    const patients = await fetchPatients()
    await populateActivePacks(patients)
    setPatients(patients)
  }

  async function addPatient (patient) {
    setPatients(patients => [...patients, patient])
  }

  return (
    <Container>
      <Row style={{ marginBottom: '30px' }}>
        <Col>
          <H2>List of Patients</H2>
        </Col>
        <Col>
          <InputGroup leftIcon='search' type='search' large onChange={(e) => setFilterText(e.target.value)} />
        </Col>
        {['pharmacy', 'admin'].includes(role) &&
        <Col>
          <Button icon='plus' large style={{ float: 'right' }} text='Add patient' intent='primary' onClick={() => setDialogOpen(true)} />
          <Dialog isOpen={isDialogOpen}>
            <PatientForm setDialogOpen={setDialogOpen} returnPatient={addPatient} onSave={fetchData} />
          </Dialog>
        </Col>}
      </Row>
      <Row>
        <Col>
          <PatientsTable patients={patients} history={history} filterText={filterText} />
        </Col>
      </Row>
    </Container>
  )
}

export default PatientsList
