import semver from 'semver'

export function filterBy (field) {
  return function (filter, row, column) {
    const id = filter.pivotId || filter.id
    if (!row[id]) return false
    const value = field ? row[id][field] : row[id]
    return String(value).toLowerCase().includes(filter.value.toLowerCase())
  }
}

export function sortBy (field) {
  return function (a, b) {
    if (!b) return 1
    if (!a) return -1
    if (field) {
      a = a[field]
      b = b[field]
    }
    if (Number.isInteger(a) && Number.isInteger(b)) return a > b ? 1 : -1
    return String(a).toLowerCase() > String(b).toLowerCase() ? 1 : -1
  }
}

export function sortByVersion (a, b) {
  const aValue = semver.valid(a) || '0.0.0'
  const bValue = semver.valid(b) || '0.0.0'
  if (aValue === bValue) return 0
  return semver.gt(aValue, bValue) ? 1 : -1
}
