import React from 'react'
import moment from 'moment-timezone'
import ColumnHeader from './column-header'
import css from './styles.module.css'

function RowDescription ({ date, isFirst }) {
  return (
    <div className={css.rowDescription}>
      <div className={date ? css.weekDay : css.none}>{date ? date.format('ddd').toUpperCase() : ''}</div>
      <div className={date ? css.date : css.none}>{date ? date.format('D MMM') : ''}</div>
      <div className={isFirst ? css.firstDay : css.none}>First Day</div>
    </div>
  )
}

function getRowDates (columns) {
  const rowDates = []

  for (let i = 0; i < 7; i++) {
    let date = ''
    columns.forEach(column => {
      date = date || column.props.column[`row${i}`].dateToOpenFrom
    })
    rowDates.push(date)
  }

  return rowDates
}

function RowDescriptions (props) {
  const startDate = moment(props.startDate).tz(props.timezone)

  const rows = getRowDates(props.columns)
    .map((rowDate, i) => {
      return <RowDescription
        isFirst={rowDate && startDate.isSame(rowDate, 'day')}
        date={rowDate && moment(rowDate).tz(props.timezone)}
        key={i}
      />
    })

  return (
    <div className={css.rowDescriptions}>
      <ColumnHeader />
      {rows}
    </div>
  )
}

export default RowDescriptions
