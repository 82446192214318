import React from 'react'
import { InputGroup, Intent, FormGroup, Colors, Classes } from '@blueprintjs/core'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

import './form-group.css'
import 'react-datepicker/dist/react-datepicker.css'

const getErrorMessage = (type, label) => {
  switch (type) {
    case 'required':
      return `${label} is required`
    case 'pattern':
      return `${label} should match pattern`
    default:
      return `${label} is not valid`
  }
}

const formatPhoneNumber = (str) => {
  let n = str.replace(/\D/g, '')
  if (n[0] === '1') n = n.substring(1)
  const match = n.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '+1 (' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return str
}

function Input (props) {
  const { type, errors, name, control, rules, defaultValue, setValue } = props

  const newProps = { ...props }
  delete newProps.label
  delete newProps.labelInfo
  delete newProps.errors
  delete newProps.control
  delete newProps.setValue

  if (type) {
    switch (type) {
      case 'date':
        delete newProps.type
        return <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({ onChange, onBlur, value }) => (
            <DatePicker
              {...newProps}
              className='bp3-input'
              onChange={onChange}
              onBlur={onBlur}
              selected={value}
            />
          )}
        />
      case 'phone':
        newProps.onChange = (e) => setValue(name, formatPhoneNumber(e.target.value))
        break
      default:
        break
    }
  }

  if (props.options) {
    if (props.options === true) return <InputGroup className={Classes.SKELETON} />

    return <Controller
      control={control}
      as={Select}
      {...newProps}
    />
  }

  return <InputGroup
    {...newProps}
    intent={errors && errors[name] ? Intent.DANGER : Intent.NONE}
  />
}

function FormElement (props) {
  const { label, labelInfo, errors, name } = props

  return <FormGroup label={label} labelInfo={labelInfo}>
    <Input {...props} />
    { errors && errors[name] && <div style={{ color: Colors.VERMILION3, marginTop: '5px' }}>{getErrorMessage(errors[name].type, label)}</div>}
  </FormGroup>
}

export default FormElement
