import React, { useState, useEffect } from 'react'
import { Tab, Tabs, Text, Button, H3, Divider, Dialog } from '@blueprintjs/core'
import { Row, Col, Container } from 'react-grid-system'
import { packsApi, patientsApi, devicesApi } from '../../api'
import authentication from '../../authentication'
import { serverErrorToast } from '../layout/toaster/toaster'
import OverviewTab from './tabs/overview'
import PacksTab from './tabs/packs'
import StatisticsTab from './tabs/statistics'
import Gateway from './tabs/gateway'
import Alerts from './tabs/alerts'
import Schedule from './tabs/schedule/schedule'
import PatientForm from './patient-form'
import css from './patient.module.css'

async function fetchPatient (medId) {
  let response
  try {
    response = await patientsApi.get('', { params: { medId } })
  } catch (err) {
    serverErrorToast(err)
    return {}
  }
  const patient = response.data && response.data[0]
  return patient || {}
}

async function fetchPacks (patientId) {
  let response
  try {
    const params = {}
    if (patientId) params.patientId = patientId
    response = await packsApi.get('', { params })
  } catch (error) {
    serverErrorToast(error)
    return []
  }
  const packs = response.data
  if (!packs || !packs.length) return []

  console.log(`Successfully fetched ${packs.length} for patient ${patientId}`)
  return packs
}

async function fetchData (medId, [setPatient, setPacks]) {
  const patient = await fetchPatient(medId)
  if (!patient) return

  setPatient(patient)
  if (patient.gatewayId) { fetchGateway(patient, setPatient) }

  const packs = await fetchPacks(patient._id)
  setPacks(packs)
}

async function fetchGateway (patient, setPatient) {
  try {
    const response = await devicesApi.get(`gateways/${patient.gatewayId}`)
    patient.gateway = response.data
    setPatient({ ...patient })
  } catch (err) {}
}

function PatientInfo ({ role, patient, packs, setPatientDialogOpen, onChange }) {
  if (!patient) return <Text>Loading...</Text>

  return (
    <Tabs large id='PatientTabs' defaultSelectedTabId='ov'>
      <Tab id='ov' title='Overview' panel={<OverviewTab patient={patient} packs={packs} onChange={onChange} />} />
      {['callcenter', 'admin'].includes(role) && <Tab id='pc' title='Packs' panel={<PacksTab patient={patient} packs={packs} onDeletePack={onChange} />} />}
      {['callcenter', 'admin'].includes(role) && <Tab id='st' title='Statistics' panel={<StatisticsTab patient={patient} packs={packs} />} />}
      {['callcenter', 'admin'].includes(role) && <Tab id='gw' title='Gateway' panel={<Gateway patient={patient} />} />}
      {['callcenter', 'admin'].includes(role) && <Tab id='al' title='Alerts' panel={<Alerts patient={patient} />} />}
      {['pharmacy', 'admin'].includes(role) && <Tab id='schedule' title='Medication Schedule' panel={<Schedule patient={patient} onSave={onChange} />} />}
      {['pharmacy', 'admin'].includes(role) && <Button icon='cog' text='Edit Info' onClick={() => setPatientDialogOpen(true)} />}
    </Tabs>
  )
}

function Patient ({ match, history }) {
  const [patient, setPatient] = useState(null)
  const [packs, setPacks] = useState(null)
  const [isPatientDialogOpen, setPatientDialogOpen] = useState(false)
  const medId = match.params.medId
  const { role } = authentication.authenticationData

  useEffect(() => {
    fetchData(medId, [setPatient, setPacks])
  }, [medId])

  function onChange () {
    setPacks(null)
    fetchData(medId, [setPatient, setPacks])
  }

  return (
    <Container>
      <Row >
        <Col md={4} >
          <Button large icon='arrow-left' text='Back' minimal onClick={() => history.push('/patients')} />
        </Col>
        <Col md={4} >
          <H3 className={css.patientName}>{patient && `${patient.firstName} ${patient.lastName}`}</H3>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col>
          <PatientInfo role={role} patient={patient} packs={packs} setPatientDialogOpen={setPatientDialogOpen} onChange={onChange} />
        </Col>
      </Row>
      {['pharmacy', 'admin'].includes(role) && patient && packs &&
      <>
        <Dialog isOpen={isPatientDialogOpen}>
          <PatientForm setDialogOpen={setPatientDialogOpen} onSave={onChange} patient={patient} />
        </Dialog>
      </>}
    </Container>
  )
}

export default Patient
