import React from 'react'
import moment from 'moment-timezone'
import breakfastIcon from './images/breakfast.svg'
import lunchIcon from './images/lunch.svg'
import supperIcon from './images/supper.svg'
import bedtimeIcon from './images/bedtime.svg'
import css from './styles.module.css'

const columnKey2Icon = {
  column0: breakfastIcon,
  column1: lunchIcon,
  column2: supperIcon,
  column3: bedtimeIcon
}

const columnKey2Name = {
  column0: 'Breakfast',
  column1: 'Lunch',
  column2: 'Supper',
  column3: 'Bedtime'
}

function ColumnIcon ({ columnKey }) {
  const icon = columnKey2Icon[columnKey]
  const alt = `${columnKey2Name[columnKey]} icon`
  return <img src={icon} alt={alt} />
}

function Timeframe ({ column, timezone }) {
  const enabledRowName = Object.keys(column).find(row => column[row].enabled)
  const enabledRow = column[enabledRowName]
  let text = '-'
  if (enabledRow && enabledRow.dateToOpenFrom) {
    text = `${moment(enabledRow.dateToOpenFrom).tz(timezone).format('hA')} - ${moment(enabledRow.dateToOpenTo).tz(timezone).format('hA')}`
  }
  return <span className={css.columnHeaderText}>{text}</span>
}

function ColumnName ({ columnKey }) {
  return <span className={css.columnHeaderText}>{columnKey2Name[columnKey]}</span>
}

function ColumnHeader ({ column, columnKey, timezone }) {
  if (!column) return <div className={css.columnHeader} />
  return (
    <div className={css.columnHeader}>
      <ColumnIcon columnKey={columnKey} />
      <Timeframe column={column} timezone={timezone} />
      <ColumnName columnKey={columnKey} />
    </div>
  )
}

export default ColumnHeader
