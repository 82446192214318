import React, { useState, useEffect } from 'react'
import { Button, Intent, H2, Card, Elevation } from '@blueprintjs/core'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { devicesApi, organizationsApi, usersApi } from '../../api'
import { errorToast, serverErrorToast, successToast } from '../layout/toaster/toaster'
import FormElement from '../common/form-group/form-group'
import css from './profile.module.css'
import authentication from '../../authentication'
import { Container, Row, Col } from 'react-grid-system'

function Profile () {
  const [loading, setLoading] = useState(false)
  const [gateways, setGateways] = useState()
  const { register, handleSubmit, errors, control, setValue } = useForm()
  const { register: registerPassword, handleSubmit: handleSubmitPassword, errors: errorsPassword, setValue: setValuePassword } = useForm()
  const { organization } = authentication.authenticationData

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData () {
    try {
      const response = await devicesApi.get('gateways?free=true')
      setGateways(response.data)
    } catch (err) {
      serverErrorToast(err)
      return []
    }
  }

  async function onSubmit ({ gatewayId, name, contactName, email, phone, address, city, state, country, zip, timezone }) {
    setLoading(true)
    try {
      await organizationsApi.patch(organization._id, {
        name,
        timezone: timezone.value,
        pharmacyGatewayId: gatewayId && gatewayId.value,
        contact: { contactName, email, phone, address, city, state, country, zip }
      })
      successToast('Information was successfully saved.')
      setLoading(false)
    } catch (err) {
      serverErrorToast(err)
      setLoading(false)
    }
  }

  async function onSubmitPassword ({ password, passwordConfirm }) {
    setLoading(true)
    try {
      if (password === passwordConfirm) {
        await usersApi.patch('password', { password })
        setValuePassword('password', '')
        setValuePassword('passwordConfirm', '')
        successToast('Password was successfully changed')
      } else errorToast('Passwords should match')
      setLoading(false)
    } catch (err) {
      serverErrorToast(err)
      setLoading(false)
    }
  }

  if (!organization) {
    return 'Loading...'
  }

  const data = {
    ...organization.contact,
    name: organization.name,
    gatewayId: organization.pharmacyGatewayId && { label: organization.pharmacyGatewayId, value: organization.pharmacyGatewayId },
    timezone: organization.timezone && { label: organization.timezone, value: organization.timezone }
  }

  return (
    <Container>
      <Row>
        <Col md={6}>
          <H2>Edit Profile</H2>
          <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
            <Card interactive elevation={Elevation.TWO}>
              <FormElement name='gatewayId' control={control} isClearable label='Pharmacy Gateway ID' options={gateways ? gateways.map(h => { return { label: h.gatewayId, value: h.gatewayId } }) : true} errors={errors} defaultValue={data.gatewayId} />
              <FormElement label='Pharmacy Name' name='name' inputRef={register({ required: true })} defaultValue={data.name} errors={errors} />
              <FormElement label='Contact Name' inputRef={register({ required: true })} name='contactName' errors={errors} defaultValue={data.contactName} />
              <FormElement label='E-mail' inputRef={register({ required: true, pattern: /\S+@\S+\.\S+/ })} name='email' errors={errors} defaultValue={data.email} />
              <FormElement label='Phone Number' labelInfo='+1 (XXX) XXX-XXXX' setValue={setValue} type='phone' name='phone' errors={errors} defaultValue={data.phone}
                inputRef={register({ required: true, pattern: /\+1[ ]?\(?[0-9]{3}\)?[ ]?[0-9]{3}-?[0-9]{4}/i })} />
              <FormElement name='timezone' isClearable rules={{ required: true }} control={control} options={moment.tz.names().map(i => { return { label: i, value: i } })} label='Timezone' errors={errors} defaultValue={data.timezone} />
              <FormElement label='Street Address' labelInfo='(INCLUDE PO BOX / UNIT#)' inputRef={register({ required: true })} name='address' errors={errors} defaultValue={data.address} />
              <FormElement label='City' inputRef={register({ required: true })} name='city' errors={errors} defaultValue={data.city} />
              <FormElement label='State/Province' inputRef={register({ required: true })} name='state' errors={errors} defaultValue={data.state} />
              <FormElement label='Country' inputRef={register({ required: true })} name='country' errors={errors} defaultValue={data.country} />
              <FormElement label='ZIP/Postal Code' inputRef={register({ required: true })} name='zip' errors={errors} defaultValue={data.zip} />
              <Button intent={Intent.SUCCESS} type='submit' loading={loading || !gateways} text='Save' />
            </Card>
          </form>
        </Col>
        <Col md={6}>
          <H2>Change Password</H2>
          <form onSubmit={handleSubmitPassword(onSubmitPassword)} className={css.form}>
            <Card interactive elevation={Elevation.TWO}>
              <FormElement label='New Password' type='password' name='password' inputRef={registerPassword({ required: true })} errors={errorsPassword} />
              <FormElement label='Confirm Password' type='password' name='passwordConfirm' inputRef={registerPassword({ required: true })} errors={errorsPassword} />
              <Button intent={Intent.DANGER} type='submit' text='Change Password' loading={loading} />
            </Card>
          </form>
        </Col>
      </Row>
    </Container>
  )
}

export default Profile
