import React from 'react'
import { Container } from 'react-grid-system'
import Statistics from '../../common/statistics/statistics'

function renderStatistics ({ patient, packs }) {
  return (
    <Container>
      <Statistics packs={packs} timezone={patient.timezone} />
    </Container>
  )
}

export default renderStatistics
