export default {
  urls: {
    users: 'https://cuepathdev.azure-api.net/organizations/users/',
    organizations: 'https://cuepathdev.azure-api.net/organizations/organizations',
    devices: 'https://cuepathdev.azure-api.net/devices/',
    packs: 'https://cuepathdev.azure-api.net/packs/packs',
    patients: 'https://cuepathdev.azure-api.net/patients/patients',
    alerts: 'https://cuepathdev.azure-api.net/patients/alerts'
  }
}
