import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { Button, Intent } from '@blueprintjs/core'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { patientsApi, organizationsApi } from '../../api'
import { serverErrorToast, successToast } from '../layout/toaster/toaster'
import FormElement from '../common/form-group/form-group'
import css from './patient.module.css'
import authentication from '../../authentication'

function PatientForm ({ setDialogOpen, patient, history, onSave }) {
  const [loading, setLoading] = useState(false)
  const { register, control, handleSubmit, errors, setValue } = useForm({
    defaultValues: patient ? {
      firstName: patient.firstName,
      lastName: patient.lastName,
      address: patient.address,
      phone: patient.phone,
      dateOfBirth: moment(patient.dateOfBirth, 'DD/MM/YYYY').toDate(),
      gender: { label: patient.gender, value: patient.gender },
      gatewayId: { label: patient.gatewayId, value: patient.gatewayId }
    } : {}
  })
  const { organization } = authentication.authenticationData

  const onSubmit = async (data) => {
    setLoading(true)
    const { firstName, lastName, address, phone, dateOfBirth } = data

    const body = {
      firstName,
      lastName,
      address,
      phone,
      dateOfBirth: moment(dateOfBirth).format('DD/MM/YYYY'),
      gender: data.gender.value
    }

    try {
      const { data: newPatient } = patient ? await patientsApi.patch(patient._id, body) : await patientsApi.post('', body)

      if (!patient) {
        organization.patientIds.push(newPatient._id)
        await organizationsApi.patch(organization._id, { patientIds: organization.patientIds })
      }

      successToast(patient ? 'Information was successfully saved.' : `Patient was successfully created.`)
      setLoading(false)
      setDialogOpen(false)
      onSave()
    } catch (err) {
      serverErrorToast(err)
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='bp3-dialog-header'>
        <span className='bp3-icon-large bp3-icon-inbox' />
        <h4 className='bp3-heading'>{patient ? 'Edit Patient' : 'Add Patient'}</h4>
        <button onClick={() => setDialogOpen(false)} type='button' aria-label='Close' className='bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross' />
      </div>
      <div className='bp3-dialog-body'>
        <div className={css.row}>
          <div className={css.col}>
            <FormElement label='First Name' inputRef={register({ required: true })} name='firstName' errors={errors} />
          </div>
          <div className={css.col}>
            <FormElement label='Last Name' inputRef={register({ required: true })} name='lastName' errors={errors} />
          </div>
        </div>
        <div className={css.row}>
          <div className={css.col}>
            <FormElement label='Gender' control={control} rules={{ required: true }} name='gender' options={[{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }]} errors={errors} />
          </div>
          <div className={css.col}>
            <FormElement label='Date Of Birth' control={control} rules={{ required: true }} name='dateOfBirth' type='date' showYearDropdown scrollableYearDropdown yearDropdownItemNumber={80} dateFormat='dd/MM/yyyy' maxDate={new Date()} errors={errors} />
          </div>
        </div>
        <FormElement label='Phone Number' labelInfo='+1 (XXX) XXX-XXXX' name='phone' setValue={setValue} type='phone' errors={errors}
          inputRef={register({ required: true, pattern: /\+1[ ]?\(?[0-9]{3}\)?[ ]?[0-9]{3}-?[0-9]{4}/i })} />
        <FormElement label='Address' inputRef={register({ required: true })} name='address' errors={errors} />
      </div>
      <div className='bp3-dialog-footer'>
        <div className='bp3-dialog-footer-actions'>
          <Button onClick={() => setDialogOpen(false)} type='button'>Cancel</Button>
          <Button intent={Intent.SUCCESS} type='submit' icon='saved' loading={loading} >Save</Button>
        </div>
      </div>
    </form>
  )
}

export default withRouter(PatientForm)
