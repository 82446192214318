import axios from 'axios'
import qs from 'qs'
import config from '../config'
import authentication from '../authentication'

const createApi = ({ baseURL }) => {
  const api = axios.create({
    baseURL,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json'
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'comma' })
    }
  })

  api.interceptors.request.use(request => {
    const { accessToken, role, organizationId } = authentication.authenticationData

    request.headers.Authorization = `Bearer ${accessToken}`
    if (role === 'admin' && organizationId) {
      request.headers['user-organization-id'] = organizationId
    }
    return request
  })
  return api
}

const patientsApi = createApi({ baseURL: config.urls.patients })
const alertsApi = createApi({ baseURL: config.urls.alerts })
const devicesApi = createApi({ baseURL: config.urls.devices })
const organizationsApi = createApi({ baseURL: config.urls.organizations })
const usersApi = createApi({ baseURL: config.urls.users })
const packsApi = createApi({ baseURL: config.urls.packs })

export {
  packsApi,
  alertsApi,
  patientsApi,
  devicesApi,
  organizationsApi,
  usersApi
}
