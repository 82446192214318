import auth0 from 'auth0-js'
import { v4 as uuid } from 'uuid'
import { organizationsApi } from '../api'
import { serverErrorToast } from '../components/layout/toaster/toaster'

const ALLOWED_PERMISSIONS = ['admin', 'callcenter', 'pharmacy']

const promisify = (fn, context) => {
  return (...args) => {
    return new Promise((resolve, reject) => {
      fn.call(context, ...args, (err, value) => {
        if (err) return reject(err)
        resolve(value)
      })
    })
  }
}

const parseJwt = (token) => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

function Auth0Service () {
  this.authenticationData = {}
  this.subscriptions = {}

  const auth = new auth0.WebAuth({
    domain: 'cuepath.auth0.com',
    clientID: 'ZKeGyijpHSXn3dJz3qFOqoB7ob5m0B2h',
    redirectUri: `${window.location.origin}/auth0_callback`,
    responseType: 'token id_token',
    scope: 'openid email profile picture',
    audience: 'cpi-api-gateway'
  })

  this.login = () => {
    window.localStorage.setItem('previousRoute', window.location.pathname)
    auth.authorize()
  }

  this.handleAuthentication = async (hash) => {
    try {
      if (hash) {
        const parseHash = promisify(auth.parseHash, auth)
        const authResult = await parseHash({ hash })
        this.setSession(authResult)
      } else {
        if (window.localStorage.getItem('previousRoute')) this.renewSession()
        else this.login()
      }
    } catch (err) {
      console.log('handleAuthentication error:', err)
    }
  }

  this.renewSession = async () => {
    try {
      const checkSession = promisify(auth.checkSession, auth)
      const authResult = await checkSession({})
      if (authResult && authResult.accessToken && authResult.idToken) this.setSession(authResult)
      else this.clear()
    } catch (err) {
      this.logout()
      console.log(`Could not get a new token (${err.error}: ${err.error_description}).`)
      this.logout()
    }
  }

  this.setSession = ({ accessToken, idTokenPayload }) => {
    const accessTokenPayload = parseJwt(accessToken)

    this.authenticationData = {
      isAuthenticated: true,
      accessToken,
      hasPermissions: accessTokenPayload.permissions.some(p => ALLOWED_PERMISSIONS.includes(p)),
      role: accessTokenPayload.permissions[0],
      organizationId: accessTokenPayload['https://cuepath.com/organizationId'],
      user: {
        id: idTokenPayload.sub,
        name: idTokenPayload.name,
        email: idTokenPayload.email,
        picture: idTokenPayload.picture
      }
    }

    if (this.authenticationData.role === 'admin') {
      this.authenticationData.organizationId = window.localStorage.getItem('sessionOrganizationId')
    }

    if (this.authenticationData.organizationId) {
      organizationsApi.get(this.authenticationData.organizationId)
        .then(response => { this.authenticationData.organization = response.data })
        .catch(err => serverErrorToast(err))
    }

    window.localStorage.setItem('isAuthenticated', true)
    this.updateSubscriptions()
  }

  this.logout = () => {
    this.clear()
    auth.logout({ returnTo: window.location.origin })
  }

  this.getPreviousRoute = () => {
    return window.localStorage.getItem('previousRoute') || '/'
  }

  this.setSessionOrganizationId = (id) => {
    window.localStorage.setItem('sessionOrganizationId', id)
    this.authenticationData.organizationId = id
    window.location.href = '/'
  }

  this.subscribe = (callback) => {
    const subscriptionId = uuid()
    this.subscriptions[subscriptionId] = callback
    return subscriptionId
  }

  this.unsubscribe = (subscriptionId) => {
    delete this.subscriptions[subscriptionId]
  }

  this.updateSubscriptions = () => {
    const objForEach = (obj, fn) => Object.keys(obj).forEach(key => fn(obj[key], key))
    objForEach(this.subscriptions, sub => sub && sub({ ...this.authenticationData }))
  }

  this.clear = () => {
    this.authenticationData = {}
    window.localStorage.removeItem('previousRoute')
    window.localStorage.removeItem('isAuthenticated')
    this.updateSubscriptions()
  }
}

export default Auth0Service
