import React from 'react'
import { Row } from 'react-grid-system'
import Skeleton from 'react-loading-skeleton'
import Pack from '../../common/pack'
import css from '../patient.module.css'

import { sortDescending } from '../../../utils/packs'

function renderPacks (patient, packs, onDeletePack) {
  return sortDescending(packs).map(pack => {
    return (
      <div key={pack._id} className={css.packsListItem}>
        <Pack pack={pack} options={{ timezone: patient.timezone }} onDelete={onDeletePack} />
      </div>)
  })
}
function renderPatientPacks ({ patient, packs, onDeletePack }) {
  if (!packs) {
    return (
      <div className={css.packsSkeleton}>
        <Skeleton />
        <Skeleton count={5} />
      </div>
    )
  }

  return (
    <div>
      <Row style={{ justifyContent: 'center' }}>
        {renderPacks(patient, packs, onDeletePack)}
      </Row>
    </div>
  )
}

export default renderPatientPacks
