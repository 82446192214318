import moment from 'moment-timezone'

const TIME_OFFSET_THRESHOLD = 1
const ADHERENCE_THRESHOLD = 80
const NUM_COLUMNS = 4
const NUM_ROWS = 7
const COLUMN_TO_TIME_MAP = {
  column0: 'breakfast',
  column1: 'lunch',
  column2: 'supper',
  column3: 'bedtime'
}

/**
 * @param {Object} dayStats
 * @param {String} status
 * @param {Number} value
 */
function incrementStats (dayStats, status, value) {
  if (!dayStats[status]) dayStats[status] = 0
  if (value) { dayStats[status] += value } else { dayStats[status]++ }
}

/**
 * @param {Object} stats
 * @param {Number} offset
 */
function updateOffsetStats (stats, offset) {
  if (!offset && offset !== 0) return
  if (!stats.timeOffset) {
    stats.timeOffset = {
      sum: 0,
      absSum: 0,
      count: 0,
      avg: null,
      absAvg: null
    }
  }
  const sum = stats.timeOffset.sum += offset
  const absSum = stats.timeOffset.absSum += Math.abs(offset)
  const count = ++stats.timeOffset.count
  stats.timeOffset.absAvg = absSum / count
  stats.timeOffset.avg = sum / count
}

/**
 * @param {Object} stats
 * @param {Number} offset
 */
function updateAdherenceStats (stats, status) {
  if (status === 'no timestamp' || status === 'await' || status === 'disabled') return
  if (!stats.adherence) {
    stats.adherence = {
      opened: 0,
      totalCount: 0,
      value: null
    }
  }
  let opened
  if (status === 'ok' || status === 'early' || status === 'late') { opened = ++stats.adherence.opened } else { opened = stats.adherence.opened }
  const total = ++stats.adherence.totalCount
  stats.adherence.value = 100 * opened / total
}

/**
 * @param {Array} packs
 */
function calculateCalendarStats (packs, timezone) {
  let packsCalendarStats = {}
  packs.forEach((pack) => {
    const cavities = pack.cavities
    for (let i = 0; i < NUM_COLUMNS; i++) {
      const column = `column${i}`
      for (let j = 0; j < NUM_ROWS; j++) {
        const row = `row${j}`
        const cavity = cavities[column][row]
        if (cavity && cavity.enabled) {
          const openDate = moment(cavity.dateToOpenFrom).tz(timezone)
          const year = openDate.year()
          const month = openDate.month()
          const day = openDate.date()
          if (!packsCalendarStats[year]) packsCalendarStats[year] = {}
          if (!packsCalendarStats[year][month]) packsCalendarStats[year][month] = {}
          if (!packsCalendarStats[year][month][day]) {
            packsCalendarStats[year][month][day] = {
              day: {},
              breakfast: '',
              lunch: '',
              supper: '',
              bedtime: ''
            }
          }
          let stats = packsCalendarStats[year][month][day]
          incrementStats(stats.day, cavity.status)
          stats[COLUMN_TO_TIME_MAP[column]] = cavity.status
        }
      }
    }
  })
  return packsCalendarStats
}

/**
 * @param {Object} compartment
 * @param {String} status
 * @param {String} timezone
 */
function getHoursOffTimeframe (compartment, status) {
  if (status === 'ok') return 0

  const openTime = moment(compartment.dateOpened)
  if (status === 'early') return openTime.diff(moment(compartment.dateToOpenFrom), 'minutes') / 60
  if (status === 'late') return openTime.diff(moment(compartment.dateToOpenTo), 'minutes') / 60
}

/**
 * @param {Array} packs
 * @param {Date} from
 * @param {Date} to
 * @param {String} timezone
 */
function calculateWeekdayAndTimeframeStats (packs, from, to, timezone) {
  const weekdayAndTimeframeStats = {}

  // function to compute the average early/late hours
  // absAvg is a mean error to catch this: if the patient opens the cavities alternating early and late but never ontime, the average is close to zero but the error is not, and it should generate an alert
  const computeTimeOffset = (compartment, weekday, timeframe, status) => {
    const offset = getHoursOffTimeframe(compartment, status)
    // early/late hours for the weekday x timeframe product
    updateOffsetStats(weekdayAndTimeframeStats[weekday][timeframe], offset)
    // early/late hours sumarized by the weekday
    updateOffsetStats(weekdayAndTimeframeStats[weekday], offset)
    // early/late hours sumarized by the timeframe
    updateOffsetStats(weekdayAndTimeframeStats[timeframe], offset)
    // overall early/late hours
    updateOffsetStats(weekdayAndTimeframeStats, offset)
  }

  const computeAdherence = (compartment, weekday, timeframe, status) => {
    // compute the adherence for the weekday x timeframe product
    updateAdherenceStats(weekdayAndTimeframeStats[weekday][timeframe], status)
    // compute the adherence sumarized by the weekday
    updateAdherenceStats(weekdayAndTimeframeStats[weekday], status)
    // compute the adherence sumarized by the timeframe
    updateAdherenceStats(weekdayAndTimeframeStats[timeframe], status)
    // compute the overall adherence
    updateAdherenceStats(weekdayAndTimeframeStats, status)
  }

  // get only packs that started between one week before the start date and the end date, to avoid iterating over packs that don't contain cells within the desired dates
  const filteredPacks = packs.filter(pack => {
    const startDate = moment(pack.startDate).tz(timezone)
    return startDate.isSameOrAfter(moment(from).subtract(7, 'days')) && startDate.isSameOrBefore(to)
  })

  filteredPacks.forEach(pack => {
    const cavities = pack.cavities
    for (let i = 0; i < NUM_COLUMNS; i++) {
      const column = `column${i}`
      for (let j = 0; j < NUM_ROWS; j++) {
        const row = `row${j}`
        const cavity = cavities[column][row]
        const toOpen = moment(cavity.dateToOpenFrom).tz(timezone)
        const status = cavity.status
        if (toOpen < from || toOpen > to) continue
        if (status === 'no timestamp' || status === 'await' || status === 'disabled') continue
        const timeframe = COLUMN_TO_TIME_MAP[column]
        const weekday = moment(cavity.dateToOpenFrom).tz(timezone).format('dddd')
        if (!weekdayAndTimeframeStats[weekday]) weekdayAndTimeframeStats[weekday] = {}
        if (!weekdayAndTimeframeStats[weekday][timeframe]) weekdayAndTimeframeStats[weekday][timeframe] = {}
        if (!weekdayAndTimeframeStats[timeframe]) weekdayAndTimeframeStats[timeframe] = {}
        computeTimeOffset(cavity, weekday, timeframe, status)
        computeAdherence(cavity, weekday, timeframe, status)
      }
    }
  })

  return weekdayAndTimeframeStats
}

/**
 * @param {Object} timeOffset
 * @param {String} [weekday]
 * @param {String} [timeframe]
 */
function getTimeOffsetMessage (timeOffset, label) {
  if (!timeOffset || !timeOffset.count) return
  const timeText = time => (
    Math.round(time) > 1 ? `${Math.round(time)} hours` : `${Math.round(time)} hour`
  )
  if (timeOffset.avg >= TIME_OFFSET_THRESHOLD) {
    return `The ${label} medication was taken late by ${timeText(timeOffset.avg)} on average`
  }
  if (timeOffset.avg <= -TIME_OFFSET_THRESHOLD) {
    return `The ${label} medication was taken early by ${timeText(-timeOffset.avg)} on average`
  }
  if (timeOffset.absAvg >= TIME_OFFSET_THRESHOLD) {
    return `The ${label} medication was taken off the timeframe by ${timeText(timeOffset.absAvg)} on average`
  }
}

/**
 * @param {Object} timeOffset
 * @param {String} [weekday]
 * @param {String} [timeframe]
 */
function getAdherenceMessage (adherence) {
  if (!adherence || !adherence.value === null) return
  if (Math.floor(adherence.value) < ADHERENCE_THRESHOLD) {
    return `The overall adherence is ${Math.round(adherence.value)}%`
  }
}

/**
   * @param {Number} month
   * @param {Number} year
   */
function getStatisticsForMonth (packsCalendarStats, year, month) {
  if (!packsCalendarStats[year] || !packsCalendarStats[year][month]) return null
  let monthStats = {
    day: {},
    breakfast: {},
    lunch: {},
    supper: {},
    bedtime: {}
  }
  Object.keys(packsCalendarStats[year][month]).map(day => packsCalendarStats[year][month][day]).forEach(dayStats => {
    Object.keys(dayStats.day).forEach(status => {
      incrementStats(monthStats.day, status, dayStats.day[status])
    })
    incrementStats(monthStats.breakfast, dayStats.breakfast)
    incrementStats(monthStats.lunch, dayStats.lunch)
    incrementStats(monthStats.supper, dayStats.supper)
    incrementStats(monthStats.bedtime, dayStats.bedtime)
  })
  return monthStats
}
/**
   * @param {Array} packs
   * @param {Date} from
   * @param {Date} to
   */
export function getMonthlyStatisticsBetweenDates (packs, from, to, timezone) {
  const packsCalendarStats = calculateCalendarStats(packs, timezone)
  let statistics = []
  // Date to start the statistic collection
  let date = moment(from).tz(timezone)
  while (date.isSameOrBefore(to)) {
    let monthStats = {
      date: moment(date),
      statistics: {}
    }
    monthStats.statistics = getStatisticsForMonth(packsCalendarStats, date.year(), date.month())
    statistics.push(monthStats)
    date.add(1, 'months')
  }
  return statistics
}
/**
   * @param {Array} packs
   * @param {Date} from
   * @param {Date} to
   */
export function getWeekdaysStatisticsBetweenDates (packs, from, to, timezone) {
  const packsCalendarStats = calculateCalendarStats(packs, timezone)
  const weekdaysStatistics = {}
  let date = moment(from).tz(timezone)
  while (date.isSameOrBefore(to)) {
    const year = date.year()
    const month = date.month()
    const day = date.date()
    const weekday = date.format('ddd')
    if (packsCalendarStats[year] && packsCalendarStats[year][month] && packsCalendarStats[year][month][day]) {
      Object.keys(packsCalendarStats[year][month][day].day).forEach(status => {
        if (status !== 'await' && status !== 'no timestamp' && status !== 'disabled') {
          if (!weekdaysStatistics[weekday]) weekdaysStatistics[weekday] = {}
          if (!weekdaysStatistics[weekday][status]) weekdaysStatistics[weekday][status] = 0
          weekdaysStatistics[weekday][status] += packsCalendarStats[year][month][day].day[status]
        }
      })
    }
    date.add(1, 'days')
  }
  return weekdaysStatistics
}
/**
  * @param {Array} packs
  * @param {Date} from
  * @param {Date} to
  */
export function overallStatisticsBetweenDates (packs, from, to, timezone) {
  const packsCalendarStats = calculateCalendarStats(packs, timezone)
  let statistics = {
    day: {},
    breakfast: {},
    lunch: {},
    supper: {},
    bedtime: {}
  }
  // Date to start the statistic collection
  let date = moment(from).tz(timezone)
  while (date.isSameOrBefore(to)) {
    const year = date.year()
    const month = date.month()
    const day = date.date()
    if (packsCalendarStats[year] && packsCalendarStats[year][month] && packsCalendarStats[year][month][day]) {
      const dayStats = packsCalendarStats[year][month][day]
      Object.keys(dayStats.day).forEach(status => {
        incrementStats(statistics.day, status, dayStats.day[status])
      })
      incrementStats(statistics.breakfast, dayStats.breakfast)
      incrementStats(statistics.lunch, dayStats.lunch)
      incrementStats(statistics.supper, dayStats.supper)
      incrementStats(statistics.bedtime, dayStats.bedtime)
    }
    date.add(1, 'day')
  }
  return statistics
}
/**
   * @param {Array} packs
   * @param {Date} from
   * @param {Date} to
   */
export function getOverallAdherenceBetweenDates (packs, from, to, timezone) {
  const packsCalendarStats = calculateCalendarStats(packs, timezone)
  let opened = 0
  let total = 0
  // Date to start the statistic collection
  let date = moment(from)
  while (date.isSameOrBefore(to)) {
    const year = date.year()
    const month = date.month()
    const day = date.date()
    if (packsCalendarStats[year] && packsCalendarStats[year][month] && packsCalendarStats[year][month][day]) {
      // eslint-disable-next-line no-loop-func
      Object.keys(packsCalendarStats[year][month][day].day).forEach(status => {
        let value = packsCalendarStats[year][month][day].day[status]
        if (status !== 'await' && status !== 'no timestamp' && status !== 'disabled') {
          if (status !== 'missed' && status !== 'wrong day') opened += value
          total += value
        }
      })
    }
    date.add(1, 'days')
  }
  if (total === 0) return null
  return 100 * opened / total
}

/**
  * @param {Array} packs
  */
export function overallStatistics (packs, timezone) {
  let stats = {
    total: 0
  }
  packs.forEach((pack) => {
    const cavities = pack.cavities
    for (let i = 0; i < NUM_COLUMNS; i++) {
      const column = `column${i}`
      for (let j = 0; j < NUM_ROWS; j++) {
        const row = `row${j}`
        const cavity = cavities[column][row]
        if (cavity && cavity.enabled) {
          incrementStats(stats, cavity.status)
          if (cavity.status !== 'await') stats.total++
        }
      }
    }
  })

  return stats
}

/**
   * @param {Array} packs
   * @param {Date} from
   * @param {Date} to
   * @param {Object} clipTiming
   */
export function getAlertMessages (packs, from, to, timezone) {
  const weekdayAndTimeframeStats = calculateWeekdayAndTimeframeStats(packs, from, to, timezone)
  let messages = []
  Object.keys(weekdayAndTimeframeStats).forEach(label => {
    const timeOffsetMessage = getTimeOffsetMessage(weekdayAndTimeframeStats[label].timeOffset, label)
    if (timeOffsetMessage) messages.push(timeOffsetMessage)
  })
  if (messages.length > 3) messages = ['Too many cavities were opened out of the time frames']
  const overallAdherenceMessage = getAdherenceMessage(weekdayAndTimeframeStats.adherence)
  if (overallAdherenceMessage) messages.push(overallAdherenceMessage)
  return messages
}

export function openedPercentage (statistics) {
  if (!statistics) return 0
  let total = 0
  let opened = 0
  Object.keys(statistics).forEach(key => {
    if (key && key !== 'await' && key !== 'no timestamp' && key !== 'disabled') {
      if (key !== 'missed' && key !== 'wrong day') opened += statistics[key]
      total += statistics[key]
    }
  })
  if (total === 0) return 0
  return 100 * opened / total
}
