import React from 'react'
import AddPackCavity from './add-pack-cavity'
import AddPackColumnHeader from './add-pack-column-header'
import AddPackColumnFooter from './add-pack-column-footer'
import css from './styles.module.css'

function AddPackColumn ({ column, columnKey, toggleCavity, toggleColumn }) {
  const cavities = Object.keys(column)
    .filter(key => key.indexOf('row') !== -1)
    .sort()
    .map(key => <AddPackCavity cavity={column[key]} key={key} toggleCavity={() => toggleCavity(columnKey, key)} />)

  return (
    <div className={css.column}>
      <AddPackColumnHeader column={column} columnKey={columnKey} start={column.start} finish={column.finish} toggleColumn={toggleColumn} />
      {cavities}
      <AddPackColumnFooter isPackColumn />
    </div>
  )
}

export default AddPackColumn
