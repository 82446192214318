import React, { useState, useEffect } from 'react'
import FixedRatioResponsiveContainer from '../../../layout/fixed-ratio-responsive-container/fixed-ratio-responsive-container'
import AddPackColumn from '../../../common/pack-settings/add-pack-column'
import { Button, Intent } from '@blueprintjs/core'
import css from '../../patient.module.css'

function PackSettings ({ patient, onSave }) {
  const [weekdaysOffset, setWeekdaysOffset] = useState()
  const [packSchedule, setPackSchedule] = useState(patient.packSchedule)

  useEffect(() => {
    setWeekdaysOffset(patient.packSchedule.weekdaysOffset || 0)
    setPackSchedule(patient.packSchedule)
  }, [patient])

  function toggleCavity (columnKey, rowKey) {
    const updatedSchedule = { ...packSchedule }
    updatedSchedule[columnKey][rowKey] = !updatedSchedule[columnKey][rowKey]
    setPackSchedule(updatedSchedule)
  }

  function toggleColumn (columnKey, columnState) {
    const updatedSchedule = { ...packSchedule }
    Object.keys(updatedSchedule[columnKey])
      .forEach(rowKey => { if (rowKey.includes('row')) updatedSchedule[columnKey][rowKey] = !columnState })
    setPackSchedule(updatedSchedule)
  }

  // function handleShiftWeekdays (x) {
  //   let newOffset = weekdaysOffset + x
  //   if (newOffset < 0) newOffset = 6
  //   if (newOffset > 6) newOffset = 0
  //   setWeekdaysOffset(newOffset)
  // }

  async function handleSubmit () {
    packSchedule.weekdaysOffset = weekdaysOffset
    onSave({ packSchedule })
  }

  const columns = Object.keys(packSchedule)
    .filter(key => key.indexOf('column') !== -1)
    .sort()
    .map(key => <AddPackColumn column={packSchedule[key]} key={key} columnKey={key} toggleCavity={toggleCavity} toggleColumn={toggleColumn} />)

  return (
    <FixedRatioResponsiveContainer width='100%' maxWidth='550px' minWidth='445px' heightOverWidthRatio={1.5}>
      <div className={css.packContainer}>
        <div className={css.packBody}>
          {columns}
        </div>
      </div>
      <Button intent={Intent.SUCCESS} onClick={handleSubmit}>Save</Button>
    </FixedRatioResponsiveContainer>
  )
}

export default PackSettings
