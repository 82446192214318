import React from 'react'
import { Button, Intent } from '@blueprintjs/core'
import breakfastIcon from './images/breakfast.svg'
import lunchIcon from './images/lunch.svg'
import supperIcon from './images/supper.svg'
import bedtimeIcon from './images/bedtime.svg'
import css from './styles.module.css'

const columnKey2Icon = {
  column0: breakfastIcon,
  column1: lunchIcon,
  column2: supperIcon,
  column3: bedtimeIcon
}

const columnKey2Title = {
  column0: 'Breakfast',
  column1: 'Lunch',
  column2: 'Supper',
  column3: 'Bedtime'
}

function ColumnIcon ({ columnKey }) {
  const icon = columnKey2Icon[columnKey]
  const alt = `${columnKey2Title[columnKey]} icon`
  return <img src={icon} alt={alt} />
}

function ColumnName ({ columnKey }) {
  return <span className={css.columnHeaderText}>{columnKey2Title[columnKey]}</span>
}

function ColumnTime ({ columnKey, start, finish }) {
  const formatHour = hour => {
    if (hour === 12) return '12PM'
    if (hour === 0) return '12AM'
    if (hour < 12) return `${hour}AM`
    else return `${hour - 12}PM`
  }
  return (
    <span className={css.columnHeaderText}>
      {formatHour(start)} - {formatHour(finish)}
    </span>
  )
}

function ColumnButton ({ column, columnKey, toggleColumn }) {
  let columnEnabled = column.row0
  for (let i = 1; i < 7 && columnEnabled !== null; i++) {
    if (column[`row${i}`] !== columnEnabled) columnEnabled = null
  }
  const intent = columnEnabled
    ? Intent.SUCCESS
    : columnEnabled === false
      ? Intent.DANGER
      : Intent.NONE
  const text = columnEnabled === false ? 'DISABLED' : 'ENABLED'
  return <Button className={css.toggleButton} text={text} intent={intent} onClick={() => toggleColumn(columnKey, columnEnabled)} />
}

function AddPackColumnHeader ({ column, columnKey, start, finish, toggleColumn, handleShiftWeekdays }) {
  if (!column) {
    return (
      <div className={css.columnHeader}>
        <Button className={css.shiftWeekdaysButton} icon='symbol-triangle-up' large minimal onClick={() => handleShiftWeekdays(1)} />
      </div>
    )
  }

  return (
    <div className={css.columnHeader}>
      <ColumnIcon columnKey={columnKey} />
      <ColumnTime columnKey={columnKey} start={start} finish={finish} />
      <ColumnName columnKey={columnKey} />
      <ColumnButton column={column} columnKey={columnKey} toggleColumn={toggleColumn} />
    </div>
  )
}

export default AddPackColumnHeader
