import React from 'react'
import { Button, Intent } from '@blueprintjs/core'
import css from './styles.module.css'

function Cavity ({ cavity, toggleCavity }) {
  const intent = cavity ? Intent.SUCCESS : Intent.DANGER
  return (
    <div className={css.cavity}>
      <Button type='button' intent={intent} text={cavity ? 'ENABLED' : 'DISABLED'} onClick={toggleCavity} />
    </div>
  )
}

export default Cavity
