import React from 'react'
import { Button } from '@blueprintjs/core'
import css from './styles.module.css'

function AddPackColumnFooter ({ isPackColumn, handleShiftWeekdays }) {
  if (isPackColumn) return <div className={css.columnFooter} />

  return (
    <div className={css.columnFooter}>
      <Button icon='symbol-triangle-down' large minimal onClick={() => handleShiftWeekdays(-1)} />
    </div>
  )
}

export default AddPackColumnFooter
