import React from 'react'
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts'
import css from './charts.module.css'

const RADIAN = Math.PI / 180

const COLORS = ['rgba(59, 174, 218, 0.8)', 'rgba(59, 174, 218, 0.1)']

function CustomLabel (props) {
  const { cx, cy, midAngle, innerRadius, outerRadius, value, name } = props
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)
  return (
    <text x={x} y={y} fill={'white'} textAnchor={x > cx ? 'start' : 'end'}>
      {name === 'OPENED' ? value + '%' : '' }
    </text>
  )
}

function TimeOfDayAdherenceChart ({ width, height, timeOfDay, adherence }) {
  const chartData = [{
    name: 'OPENED',
    value: adherence
  }, {
    name: 'MISSED',
    value: 100 - adherence
  }]
  return (
    <>
      <div className={css.chartTitle} style={{ width }}>{timeOfDay}</div>
      <ResponsiveContainer height={height} width={width}>
        <PieChart>
          <Pie data={chartData} dataKey='value' nameKey='name' label={CustomLabel} labelLine={false}>
            {chartData.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} key='index' />)}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </>
  )
}

export default TimeOfDayAdherenceChart
