import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import Select from 'react-select'
import { Alignment, Button, Classes, Navbar } from '@blueprintjs/core'
import classNames from 'classnames'
import css from './top-bar.module.css'
import { serverErrorToast } from '../toaster/toaster'
import authentication from '../../../authentication'
import { organizationsApi } from '../../../api'
import logo from '../../../images/cuepath_icon_full_color.png'

function TopBar ({ history, location, userName, userAvatar, logoutHandler }) {
  const { role, organizationId } = authentication.authenticationData
  const [organizations, setOrganizations] = useState()
  const [organization, setOrganization] = useState()

  useEffect(() => {
    if (role === 'admin') fetchData()
  }, [])

  async function fetchData () {
    try {
      const response = await organizationsApi.get()
      setOrganizations(response.data.map(o => { return { value: o._id, label: o.name } }))
      if (organizationId) {
        const org = response.data.find(o => o._id === organizationId)
        setOrganization({ value: org._id, label: org.name })
      }
    } catch (err) {
      serverErrorToast(err)
      return []
    }
  }

  return (
    <Navbar className={classNames(css.topBar, Classes.ELEVATION_4)}>
      <Navbar.Group align={Alignment.LEFT}>
        <img src={logo} alt='logo' className={css.logo} />
        <Navbar.Heading className={css.topBarHeading} onClick={() => history.push('/')} style={{ cursor: 'pointer' }}>CuePath Portal</Navbar.Heading>
        <Navbar.Divider />
        <Button minimal icon='user' text='Patients' active={location.pathname.includes('patients')} onClick={() => history.push('/patients')} />
        {['pharmacy', 'admin'].includes(role) && <Button minimal icon='cog' text='Profile' active={location.pathname.includes('profile')} onClick={() => history.push('/profile')} />}
        {['pharmacy', 'admin'].includes(role) && <Button minimal icon='manual' text='Help' active={location.pathname.includes('help')} onClick={() => history.push('/help')} />}
        {role === 'admin' && <div style={{ width: 200, marginLeft: 20 }}><Select options={organizations} value={organization} onChange={({ value }) => authentication.setSessionOrganizationId(value)} /></div>}
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Navbar.Divider />
        {userAvatar ? <img src={userAvatar} alt='avatar' className={css.avatar} /> : null}
        <span className={css.userLabel}>{userName}</span>
        {logoutHandler ? <Button text='Logout' onClick={logoutHandler} /> : null}
      </Navbar.Group>
    </Navbar>
  )
}

export default withRouter(TopBar)
