import React from 'react'
import moment from 'moment-timezone'
import classnames from 'classnames'
import css from './styles.module.css'

function formatStartDate (startDate, endDate) {
  if (!startDate || !endDate) return ''
  if (startDate.year() !== endDate.year()) return startDate.format('D MMM YYYY')
  if (startDate.month() !== endDate.month()) return startDate.format('D MMM')
  return startDate.format('D')
}

function ConnectivityStatus ({ status, isConnected }) {
  let stickerErrorMessage = ''
  if (!isConnected) {
    stickerErrorMessage = 'Pack out of range'
  }
  return (
    <div className={classnames(css.statusCircle, css[status], { [css.monitored]: isConnected, [css.notMonitored]: !isConnected })}>
      <div className={classnames(css.statusMessage)}>{stickerErrorMessage}</div>
    </div>
  )
}

function PackPeriod ({ status, startDate, endDate }) {
  return (
    <h2 className={classnames(css.header, css[status])}>
      {startDate ? `${formatStartDate(startDate, endDate)} - ${endDate.format('D MMM YYYY')}` : 'Prepared'}
    </h2>
  )
}

function PackHeader (props) {
  const { status, isConnected, timezone } = props
  const startDate = props.startDate && moment(props.startDate).tz(timezone)
  const endDate = props.endDate && moment(props.endDate).tz(timezone)
  return (
    <>
      <ConnectivityStatus status={status} isConnected={isConnected} />
      <PackPeriod status={status} startDate={startDate} endDate={endDate} />
    </>
  )
}

export default PackHeader
