import React from 'react'
import { Container } from 'react-grid-system'

function Help () {
  return <Container>
    <h1 >Frequently Asked Questions</h1>
    <ul style={{ fontSize: '1.5em' }}>
      <li><a rel='noopener noreferrer' href='https://app.cuepath.io/pharmacy/documents/How_To_Add_A_Patient_In_The_Pharmacy_App.pdf' target='_blank' class='faq-item'>How to add a patient in the Pharmacy App</a></li>
      <li><a rel='noopener noreferrer' href='https://app.cuepath.io/pharmacy/documents/How_To_Add_A_Patient_Blister_Pack.pdf' target='_blank' class='faq-item'>How to add a patient blister pack</a></li>
      <li><a rel='noopener noreferrer' href='https://app.cuepath.io/pharmacy/documents/How_To_Install_A_Gateway.pdf' target='_blank' class='faq-item'>How to install a gateway</a></li>
      <li><a rel='noopener noreferrer' href='https://app.cuepath.io/pharmacy/documents/How_To_Attach_A_Sticker.pdf' target='_blank' class='faq-item'>How to attach a sticker</a></li>
      <li><a rel='noopener noreferrer' href='https://app.cuepath.io/pharmacy/documents/How_To_Replace_Battery_Sticker.pdf' target='_blank' class='faq-item'>How to replace battery sticker</a></li>
      <li><a rel='noopener noreferrer' href='https://app.cuepath.io/pharmacy/documents/How_To_Checklist_for_Pharmacists_Initial_Setup.pdf' target='_blank' class='faq-item'>How to checklist for pharmacists initial setup</a></li>
      <li><a rel='noopener noreferrer' href='https://app.cuepath.io/pharmacy/documents/CuePath_Initial_Home_Setup.pdf' target='_blank' class='faq-item'>CuePath initial home setup</a></li>
    </ul>
  </Container>
}

export default Help
