import React, { useState, useEffect } from 'react'
import { Button, H4, H5, Popover } from '@blueprintjs/core'
import ReactTable from 'react-table'
import { alertsApi } from '../../../api'
import { sortBy, filterBy } from '../../../utils/tables'
import { serverErrorToast } from '../../layout/toaster/toaster'
import css from '../patient.module.css'

const getAlertName = (type) => {
  switch (type) {
    case 'cavitiy-missed': return 'Missed Cavity'
    case 'cavitiy-wrong-day': return 'Wrong Day Cavity'
    case 'cavitiy-late': return 'Late Cavity'
    case 'gateway-disconnected': return 'Gateway Disconnected'
    case 'pack-disconnected': return 'Pack Disconnected'
    case 'no-more-packs': return 'No more prepared packs'
    case 'pack-missed': return 'Connected pack missed'
    default: return type
  }
}

const DataBlock = (data) => {
  const blocks = []
  for (const [key, value] of Object.entries(data)) {
    const print = typeof value === 'object' ? DataBlock(value) : value.toString()
    blocks.push(<div key={key}><b>{key}</b>: {print} </div>)
  }
  return blocks
}

const DataPopover = ({ data, children }) => <Popover content={<div style={{ padding: 10 }}>{Object.entries(data).map(([key, value]) => {
  return <div key={key} style={{ marginBottom: 10}}>
    <H5>{key}</H5>
    {typeof value === 'object' ? DataBlock(value) : value.toString()}
  </div>
})}</div>} target={children} />

const Alerts = ({ patient }) => {
  const [alerts, setAlerts] = useState()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await alertsApi.get(patient._id)
        setAlerts(response?.data)
      } catch (err) {
        serverErrorToast(err)
      }
    }
    fetchData()
  }, [patient])

  if (!alerts) return null

  const columns = [{
    Header: 'Timestamp (UTC)',
    accessor: 'createdAt',
    minWidth: 180
  }, {
    Header: 'Alert',
    id: 'type',
    accessor: ({ type }) => (getAlertName(type))
  }, {
    Header: 'Pack ID',
    id: 'packId',
    accessor: ({ data }) => data.packId
  }, {
    Header: 'Details',
    id: 'data',
    accessor: ({ data }) => <DataPopover data={data}><Button icon='more' minimal /></DataPopover>
  }]

  return (
    <div className={css.overviewContainer}>
      <H4>Patient Alerts</H4>
      <ReactTable
        defaultSortMethod={sortBy()}
        defaultFilterMethod={filterBy()}
        data={alerts}
        columns={columns}
        showPageSizeOptions={false}
        defaultPageSize={100}
        minRows={1}
        freezeWhenExpanded
        filterable
        className='-striped -highlight'
      />
    </div>
  )
}

export default Alerts
