import React from 'react'
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, LabelList } from 'recharts'
import moment from 'moment-timezone'
import css from './charts.module.css'
import { getMonthlyStatisticsBetweenDates, openedPercentage } from '../../../../utils'

function CustomChartLabel (props) {
  const { x, y, width, height, value } = props
  return (
    <text x={x + width / 2} y={value ? y + height / 2 : y - 10} fill={value ? 'white' : 'black'} textAnchor='middle' dominantBaseline='middle'>
      {value + '%'}
    </text>
  )
}

function MonthlyAdherenceChart ({ packs, period, timezone, width, height, margin }) {
  const startDateInTimezone = moment.tz([period[0].year(), period[0].month()], timezone).startOf('month')
  const endDateInTimezone = moment.tz([period[1].year(), period[1].month()], timezone).endOf('month')
  const monthStatistics = getMonthlyStatisticsBetweenDates(packs, startDateInTimezone, endDateInTimezone, timezone)
  const monthlyAdherenceData = monthStatistics.map(stats => {
    return {
      name: stats.date.format('MMM/YY'),
      value: Math.round(openedPercentage(stats.statistics && stats.statistics.day))
    }
  })

  const verticalCoordinatesGenerator = ({ offset }) => {
    const verticalCoordinates = []
    for (let i = 0; i < monthlyAdherenceData.length; i++) {
      verticalCoordinates.push((offset.width - offset.width * i / monthlyAdherenceData.length) + offset.left)
    }
    return verticalCoordinates
  }

  return (
    <>
      <div className={css.chartTitle} style={{ width }}>Adherence by Month</div>
      <ResponsiveContainer width={width} height={height}>
        <BarChart data={monthlyAdherenceData} margin={margin}>
          <CartesianGrid verticalCoordinatesGenerator={verticalCoordinatesGenerator} stroke='#ddd' />
          <XAxis dataKey='name' />
          <YAxis type='number' domain={[0, 100]} />
          <Tooltip />
          <Bar dataKey='value' fill='rgba(59, 174, 218, 0.8)'>
            <LabelList dataKey='value' content={CustomChartLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

export default MonthlyAdherenceChart
