import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { Button, Intent, Text } from '@blueprintjs/core'
import { DateInput } from '@blueprintjs/datetime'
import MonthlyAdherenceChart from './charts/monthly-adherence-chart'
import WeekdayAdherenceChart from './charts/weekday-adherence-chart'
import TimeOfDayAdherenceCharts from './charts/time-of-day-adherence-charts'
import AdherenceTrendAnalysis from './charts/adherance-trend-analysis'
import css from './statistics.module.css'

function Statistics ({ packs, timezone }) {
  if (!packs) return <Text>Loading...</Text>

  const calculatePeriod = value => {
    return [
      moment().subtract(value - 1, 'month').startOf('month'),
      moment().endOf('month')
    ]
  }

  const [period, setPeriod] = useState(calculatePeriod(6))
  const [chartControlValue, setChartControlValue] = useState(6)

  const changePeriod = (startDate, endDate) => {
    setPeriod([startDate, endDate])
  }

  useEffect(() => {
    if (!packs) return
    changePeriod(...period)
  }, [packs])

  const ChartControlButton = ({ value }) => (
    <Button
      minimal
      intent={Intent.PRIMARY}
      text={`${value} months`}
      active={chartControlValue === value}
      onClick={() => {
        setChartControlValue(value)
        changePeriod(...calculatePeriod(value))
      }}
      disabled={!packs}
    />
  )
  // for monthly adherence charts and weekday adherence charts, width needs to be 99% due to bug in recharts that makes charts not resize if size is 100%
  return (
    <div className={css.statistics}>
      <div className={css.chartControl}>
        <div className={css.dateInput}>
          <span className={css.dateInputLabel}>From</span>
          <DateInput
            formatDate={date => moment(date).format('MM/YYYY')}
            parseDate={str => new Date(str)} placeholder='MM/YYYY'
            onChange={(date) => {
              date && changePeriod(moment(date).startOf('month'), period[1])
              setChartControlValue(false)
            }}
            value={new Date(period[0].format())}
            dayPickerProps={{ disabledDays: {
              from: new Date(moment(period[0]).add(1, 'day').format()),
              to: new Date(moment(period[0]).endOf('month').format())
            } }} />
        </div>
        <div className={css.dateInput}>
          <span className={css.dateInputLabel}>To</span>
          <DateInput
            formatDate={date => moment(date).format('MM/YYYY')}
            parseDate={str => new Date(str)}
            placeholder='MM/YYYY'
            onChange={(date) => {
              date && changePeriod(period[0], moment(date).endOf('month'))
              setChartControlValue(false)
            }}
            value={new Date(period[1].format())}
            dayPickerProps={{ disabledDays: {
              from: new Date(moment(period[1]).startOf('month').format()),
              to: new Date(moment(period[1]).subtract(1, 'day').format())
            } }} />
        </div>
      </div>
      <div className={css.monthButtons}>
        <ChartControlButton value={1} />
        <ChartControlButton value={3} />
        <ChartControlButton value={6} />
        <ChartControlButton value={12} />
      </div>
      <div className={css.monthlyChart}>
        <MonthlyAdherenceChart packs={packs} period={period} timezone={timezone} width='99%' height={340} margin={{ top: 5, right: 5, left: -30, bottom: -1 }} />
      </div>
      <div className={css.charts}>
        <div className={css.weekdayChart}>
          <WeekdayAdherenceChart packs={packs} period={period} timezone={timezone} width='99%' height={540} margin={{ top: 5, right: 5, left: -20, bottom: -1 }} />
        </div>
        <div className={css.timeOfDayCharts}>
          <TimeOfDayAdherenceCharts packs={packs} period={period} timezone={timezone} />
        </div>
      </div>
      <div className={css.adherenceTrendAnalysis}>
        <AdherenceTrendAnalysis packs={packs} period={period} timezone={timezone} />
      </div>
    </div>
  )
}

export default Statistics
