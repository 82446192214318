import React from 'react'
import { Classes, H6, Tag, Intent } from '@blueprintjs/core'
import { Row } from 'react-grid-system'

const patientInfoItemsStyle = {
  width: '50%',
  display: 'inline-block'
}

const getGatewayTag = patient => {
  const status = patient.gateway ? patient.gateway.isConnected ? 'connected' : 'disconnected' : 'unknown'
  const intent = status === 'connected' ? Intent.SUCCESS : Intent.DANGER

  return <>
    {patient.gatewayId}
    <Tag intent={intent} round style={{ borderCollapse: 'initial' }}>{status.toUpperCase()}</Tag>
  </>
}

export function InfoRow ({ label, value }) {
  return (
    <Row>
      <H6 className={Classes.TEXT_MUTED} style={patientInfoItemsStyle}>{label}</H6>
      <div style={patientInfoItemsStyle}>{value}</div>
    </Row>
  )
}

export function PatientInfo ({ patient, style }) {
  return (
    <div style={style}>
      <InfoRow label='Client Id' value={patient.medId} />
      <InfoRow label='Name' value={`${patient.firstName} ${patient.lastName}`} />
      <InfoRow label='Date Of Birth' value={patient.dateOfBirth} />
      <InfoRow label='Phone' value={patient.phone} />
      <InfoRow label='Address' value={patient.address} />
      <InfoRow label='Gateway' value={getGatewayTag(patient)} />
    </div>
  )
}
