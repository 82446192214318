import React from 'react'
import moment from 'moment-timezone'
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, LabelList } from 'recharts'
import css from './charts.module.css'
import { getWeekdaysStatisticsBetweenDates, openedPercentage } from '../../../../utils'

const WEEKDAYS = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun'
]

function CustomChartLabel (props) {
  const { x, y, width, height, value } = props
  return (
    <text x={value ? x + width / 2 : x + 15} y={y + height / 2} fill={value ? 'white' : 'black'} textAnchor='middle' dominantBaseline='middle'>
      {value + '%'}
    </text>
  )
}

function WeekdayAdherenceChart ({ packs, period, timezone, width, height, margin }) {
  const startDateInTimezone = moment.tz([period[0].year(), period[0].month()], timezone).startOf('month')
  const endDateInTimezone = moment.tz([period[1].year(), period[1].month()], timezone).endOf('month')
  const weekdayStatistics = getWeekdaysStatisticsBetweenDates(packs, startDateInTimezone, endDateInTimezone, timezone)
  const weekdayAdherenceData = WEEKDAYS.map(weekday => {
    return {
      name: weekday.toUpperCase(),
      value: Math.round(openedPercentage(weekdayStatistics[weekday]))
    }
  })

  const horizontalCoordinatesGenerator = ({ offset }) => {
    const verticalCoordinates = []
    for (let i = 1; i <= weekdayAdherenceData.length; i++) {
      verticalCoordinates.push((offset.height - offset.height * i / weekdayAdherenceData.length) + offset.top)
    }
    return verticalCoordinates
  }

  return (
    <>
      <div className={css.chartTitle} style={{ width }}>Adherence by Day of the Week</div>
      <ResponsiveContainer width={width} height={height}>
        <BarChart data={weekdayAdherenceData} layout='vertical' margin={margin}>
          <CartesianGrid horizontalCoordinatesGenerator={horizontalCoordinatesGenerator} stroke='#ddd' />
          <YAxis type='category' dataKey='name' />
          <XAxis type='number' domain={[0, 100]} />
          <Tooltip />
          <Bar dataKey='value' fill='rgba(59, 174, 218, 0.8)'>
            <LabelList dataKey='value' content={CustomChartLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

export default WeekdayAdherenceChart
