import React from 'react'
import moment from 'moment-timezone'
import TimeOfDayAdherenceChart from './time-of-day-adherence-chart'
import css from './charts.module.css'
import { overallStatisticsBetweenDates, openedPercentage } from '../../../../utils'

function TimeOfDayAdherenceCharts ({ packs, period, timezone }) {
  const startDateInTimezone = moment.tz([period[0].year(), period[0].month()], timezone).startOf('month')
  const endDateInTimezone = moment.tz([period[1].year(), period[1].month()], timezone).endOf('month')
  const timeOfDayStatistics = overallStatisticsBetweenDates(packs, startDateInTimezone, endDateInTimezone, timezone)
  const timeOfDayAdherenceData = Object.keys(timeOfDayStatistics).filter(key => key !== 'day').map(key => {
    return (
      // width needs to be 99% due to bug in recharts that makes charts not resize if size is 100%
      <div className={css.timeOfDayChartContainer} key={key}>
        <TimeOfDayAdherenceChart width='99%' height={250} timeOfDay={key} adherence={Math.round(openedPercentage(timeOfDayStatistics[key]))} />
      </div>
    )
  })
  return (
    <>
      <div className={css.allTimesOfDayChartsHeader}>Adherence by Time of the Day</div>
      <div className={css.allTimesOfDayChartsContainer}>
        {timeOfDayAdherenceData}
      </div>
    </>
  )
}

export default TimeOfDayAdherenceCharts
