import moment from 'moment'

export function findCurrentPack (packs) {
  const pack = packs.find(pack => moment().isBetween(pack.startDate, pack.endDate))
  return pack
}

export function findNextPreparedPack (packs) {
  const mapped = packs.map(function (pack, i) {
    return { index: i, pack }
  })

  // sorting the mapped array containing the reduced values
  mapped.sort((a, b) => moment(a.pack.startDate).isAfter(moment(b.pack.startDate)) ? 1 : -1)

  let nextPreparedPackMappedIndex = mapped.findIndex(element => moment().isAfter(element.pack.startDate)) - 1
  if (nextPreparedPackMappedIndex < 0) return null

  const nextPreparedPackIndex = mapped[nextPreparedPackMappedIndex].index

  return packs[nextPreparedPackIndex]
}

export function findLastPack (packs) {
  if (!packs.length) return null

  packs.sort((a, b) => moment(a.startDate).isBefore(moment(b.startDate)) ? 1 : -1)
  return packs[0]
}

export function sortDescending (packs) {
  const copied = [...packs]

  // sorting the mapped array containing the reduced values
  copied.sort((a, b) => moment(a.startDate).isBefore(moment(b.startDate)) ? 1 : -1)

  return copied
}

export function getTodayStatus (pack, timezone) {
  const todayCavityRow = Object.keys(pack.cavities.column0)
    .filter(key => key.indexOf('row') !== -1)
    .find(key => moment(pack.cavities.column0[key].dateToOpenFrom).tz(timezone).isSame(moment(), 'day'))

  if (!todayCavityRow) return null

  const states = Object.values(pack.cavities)
    .map(column => column[todayCavityRow].status)
  return states
}

export function getFirstEnabledCavityKeys (pack) {
  if (!pack) return null
  for (let rowIndex = 0; rowIndex < 7; rowIndex++) {
    const rowKey = `row${rowIndex}`
    for (let columnIndex = 0; columnIndex < 4; columnIndex++) {
      const columnKey = `column${columnIndex}`
      if (pack.cavities[columnKey][rowKey].enabled) return { columnKey, rowKey, columnIndex, rowIndex }
    }
  }
  return null
}
