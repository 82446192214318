import React from 'react'
import Column from './column'
import RowDescriptions from './row-descriptions'
import css from './styles.module.css'

function PackBody (props) {
  const columns = Object.keys(props.cavities)
    .filter(key => key.indexOf('column') !== -1 && key !== 'column4')
    .sort()
    .map(key => <Column column={props.cavities[key]} key={key} columnKey={key} timezone={props.timezone} />)

  return (
    <div className={css.packBody}>
      <RowDescriptions startDate={props.startDate} timezone={props.timezone} columns={columns} />
      {columns}
    </div>
  )
}

export default PackBody
